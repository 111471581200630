import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CallToAction.module.css';

function CallToAction() {
  return (
    <section className={styles.callToAction}>
      <h2>Ready to Transform Your AI Workflow?</h2>
      <p>
        Experience the power of advanced AI orchestration with OpenHadron. Streamline your processes and unlock new possibilities.
      </p>
      <Link to="/products/openhadron" className={styles.primaryButton}>
        Get Started Today
      </Link>
    </section>
  );
}

export default CallToAction;
