// src/pages/terms/TermsPage/TermsPage.js
import React from 'react';
import styles from './TermsPage.module.css';

function TermsPage() {
  return (
    <div className={styles.termsPage}>
      <h1>Terms of Service</h1>
      <section className={styles.termsSection}> 
        <h2>1. Introduction</h2>
        <p>
          Welcome to SynchrotronAI! These Terms of Service govern your use of our platform and services. By accessing or using our services, you agree to be bound by these terms.
        </p>
      </section>
      <section className={styles.termsSection}>
        <h2>2. User Responsibilities</h2>
        <p>
          You are responsible for your use of the services and for any content you post. You agree not to use the services for any illegal or unauthorized purpose and to comply with all laws and regulations.
        </p>
      </section>
      <section className={styles.termsSection}>
        <h2>3. Intellectual Property</h2>
        <p>
          All content and software provided on the SynchrotronAI platform are the property of SynchrotronAI and its licensors. You agree not to reproduce, distribute, or create derivative works from our content without our written permission.
        </p>
      </section>
      <section className={styles.termsSection}>
        <h2>4. Limitation of Liability</h2>
        <p>
          SynchrotronAI will not be liable for any indirect, incidental, or consequential damages arising from your use of the platform or services.
        </p>
      </section>
      <section className={styles.termsSection}>
        <h2>5. Changes to Terms</h2>
        <p>
          We may update these terms from time to time. Any changes will be posted on this page, and we will notify you of significant changes. By continuing to use the services, you agree to the updated terms.
        </p>
      </section>
    </div>
  );
}

export default TermsPage;
