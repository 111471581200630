// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

function Footer() {
  return (
    <footer className={styles.footer}> {/* Use styles.footer */}
      <div className={styles.footerMainBox}> {/* Use styles.footerMainBox */}
        <div className={styles.footerSections}> {/* Use styles.footerSections */}
          <div className={styles.footerColumn}> {/* Use styles.footerColumn */}
            <h4>Our Research</h4>
            <ul>
              <li><Link to="/research">Overview</Link></li>
              <li><Link to="/research/index">Index</Link></li>
            </ul>
            <h4>Latest Advancements</h4>
            <ul>
              <li><Link to="/research/autonomous-systems">Autonomous Systems</Link></li>
            </ul>
          </div>
          <div className={styles.footerColumn}> {/* Use styles.footerColumn */}
            <h4>OpenHadron</h4>
            <ul>
              <li><Link to="/products/openhadron/individuals">For Individuals</Link></li>
              <li><Link to="/products/openhadron/teams">For Teams</Link></li>
              <li><Link to="/products/openhadron/enterprise">For Enterprises</Link></li>
            </ul>
          </div>
          <div className={styles.footerColumn}> {/* Use styles.footerColumn */}
            <h4>API</h4>
            <ul>
              <li><Link to="/api/overview">Platform Overview</Link></li>
              <li><Link to="/api/pricing">Pricing</Link></li>
              <li><Link to="/api/documentation">Documentation</Link></li>
            </ul>
            <h4>Developer Tools</h4>
            <ul>
              <li><Link to="/developers/sdks">SDKs</Link></li>
              <li><Link to="/developers/code-samples">Code Samples</Link></li>
              <li><Link to="/developers/api-integrations">API Integrations</Link></li>
            </ul>
          </div>
          <div className={styles.footerColumn}> {/* Use styles.footerColumn */}
            <h4>Company</h4>
            <ul>
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/news">News</Link></li>
              <li><Link to="/careers">Careers</Link></li>
              <li><Link to="/about-us/charter">Our Charter</Link></li>
              <li><Link to="/about-us/security">Security</Link></li>
              <li><Link to="/about-us/residency">Residency</Link></li>
            </ul>
            <h4>Community</h4>
            <ul>
              <li><Link to="/community/events">Events</Link></li>
              <li><Link to="/community/forums">Forums</Link></li>
              <li><Link to="/community/ambassadors">Ambassadors</Link></li>
            </ul>
          </div>
          <div className={styles.footerColumn}> {/* Use styles.footerColumn */}
            <h4>Terms & Policies</h4>
            <ul>
              <li><Link to="/terms">Terms of Use</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/brand-guidelines">Brand Guidelines</Link></li>
              <li><Link to="/policies">Other Policies</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.footerBottomBox}> {/* Use styles.footerBottomBox */}
        <p>&copy; 2024 SynchrotronAI. All rights reserved.</p>
        <div className={styles.socialIcons}> {/* Use styles.socialIcons */}
          {/* Social media links */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
