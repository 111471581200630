import React, { useState } from 'react';
import styles from './ChatHistoryPage.module.css';

const ChatHistoryPage = () => {
  // Mock data for chat history
  const [chatHistory, setChatHistory] = useState([
    { id: 1, title: 'Project Discussion', date: '2023-09-15', preview: 'Let\'s discuss the new features...' },
    { id: 2, title: 'Bug Report', date: '2023-09-14', preview: 'I found an issue with the login...' },
    { id: 3, title: 'Team Meeting', date: '2023-09-13', preview: 'Agenda for tomorrow\'s meeting...' },
  ]);

  return (
    <div className={styles.chatHistoryPage}>
      <h1 className={styles.pageTitle}>Chat History</h1>
      
      <div className={styles.searchBar}>
        <input type="text" placeholder="Search chats..." className={styles.searchInput} />
        <button className={styles.searchButton}>Search</button>
      </div>

      <ul className={styles.chatList}>
        {chatHistory.map((chat) => (
          <li key={chat.id} className={styles.chatItem}>
            <div className={styles.chatInfo}>
              <h3 className={styles.chatTitle}>{chat.title}</h3>
              <p className={styles.chatDate}>{chat.date}</p>
            </div>
            <p className={styles.chatPreview}>{chat.preview}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatHistoryPage;