import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Create the AuthContext
export const AuthContext = createContext();

// Helper function to decode JWT and get expiration time
const getExpirationDate = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.exp) {
      return new Date(decodedToken.exp * 1000); // Convert from seconds to milliseconds
    }
  } catch (error) {
    console.error('Failed to decode token:', error);
  }
  return null;
};

// AuthProvider component to wrap around parts of the app that need access to auth state
export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // Store user details
  const [loading, setLoading] = useState(true); // Handle loading state
  const [error, setError] = useState(null); // Handle errors
  const [token, setToken] = useState(null); // Store JWT token

  // Function to automatically logout when JWT expires
  const scheduleAutoLogout = useCallback((token) => {
    const expirationDate = getExpirationDate(token);
    if (!expirationDate) return;

    const timeUntilLogout = expirationDate.getTime() - new Date().getTime();
    if (timeUntilLogout <= 0) {
      handleLogout();
      return;
    }

    const timeoutId = setTimeout(() => {
      handleLogout();
    }, timeUntilLogout);

    return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts or token changes
  }, []);

  // Function to check authentication status
  const checkAuth = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/auth/user', {
        withCredentials: true, // Send cookies with the request
      });

      if (response.data.authenticated) {
        setAuthenticated(true);
        setUser(response.data.user);
        if (response.data.token) {
          const token = response.data.token;
          setToken(token);
          scheduleAutoLogout(token);
        }
      } else {
        setAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setAuthenticated(false);
      setUser(null);
      setError('Failed to authenticate.');
    } finally {
      setLoading(false);
    }
  };

  // Function to refresh JWT token periodically or on demand
  const refreshAuth = async () => {
    try {
      const response = await axios.get('/api/auth/refresh', {
        withCredentials: true,
      });

      if (response.data.message === 'Token refreshed') {
        setAuthenticated(true);
        const newToken = response.data.token; // Assuming new token is returned in response
        setToken(newToken); // Update token in state
        scheduleAutoLogout(newToken); // Schedule auto logout with the new token
      } else {
        setAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error refreshing authentication:', error);
      setAuthenticated(false);
      setUser(null);
    }
  };

  // Function to handle user logout
  const handleLogout = async () => {
    try {
      await axios.get('/api/auth/logout', {
        withCredentials: true,
      });
      setAuthenticated(false);
      setUser(null);
      setToken(null); // Clear token on logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Automatically check authentication status on component mount
  useEffect(() => {
    checkAuth(); // Initial check for authentication

    const intervalId = setInterval(() => {
      refreshAuth(); // Periodically refresh the authentication token
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [scheduleAutoLogout]);

  // Function to handle manual token storage and update
  const storeTokenAndScheduleLogout = (token) => {
    setToken(token); // Store token in state
    scheduleAutoLogout(token); // Schedule logout with the given token
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        token, // Provide token in context
        setUser,
        loading,
        error,
        refreshAuth,
        handleLogout,
        storeTokenAndScheduleLogout, // Expose function to manually update token and schedule logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};