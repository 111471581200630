// src/pages/products/ProductsPage/ProductsPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProductsPage.module.css';

function ProductsPage() {
  return (
    <main className={styles.productsPage}>
      {/* Hero Section */}
      <section className={styles.productsHero}>
        <h1>Discover OpenHadron</h1>
        <p>AI orchestration reimagined for the modern world.</p>
        <button className={styles.heroButton}>Get Started</button>
      </section>

      {/* Product Overview Section */}
      <section className={styles.productOverview}>
        <div className={styles.overviewContent}>
          <div className={styles.overviewIcon}>
            <i className="fas fa-atom"></i>
          </div>
          <div className={styles.overviewText}>
            <h2>What is OpenHadron?</h2>
            <p>
              OpenHadron is a powerful AI orchestrator that seamlessly integrates with leading AI platforms and third-party solutions.
              It enables businesses to automate complex tasks, streamline workflows, and unlock new possibilities in AI-driven automation.
            </p>
            <Link to="/products/openhadron" className={styles.learnMore}>Learn More &raquo;</Link>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className={styles.productFeatures}>
        <h2>Key Features</h2>
        <div className={styles.featuresGrid}>
          <div className={styles.featureItem}>
            <i className="fas fa-plug"></i>
            <h4>Seamless Integration</h4>
            <p>Connect with top AI platforms effortlessly.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-cogs"></i>
            <h4>Agent-Based Automation</h4>
            <p>Install our agent to carry out tasks directly on your devices.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-lock"></i>
            <h4>Advanced Security</h4>
            <p>Robust security features to protect your data.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-chart-line"></i>
            <h4>Scalable Architecture</h4>
            <p>Designed to grow with your business needs.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-user-friends"></i>
            <h4>User-Friendly Interface</h4>
            <p>Intuitive design for easy orchestration.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-tools"></i>
            <h4>Customizable Workflows</h4>
            <p>Tailor automation processes to your requirements.</p>
          </div>
        </div>
      </section>

      {/* Use Cases Section */}
      <section className={styles.productUseCases}>
        <h2>Transforming Industries</h2>
        <div className={styles.useCasesGrid}>
          <div className={styles.useCaseItem}>
            <div className={styles.useCaseIcon}>
              <i className="fas fa-heartbeat"></i>
            </div>
            <h4>Healthcare</h4>
            <p>Automate patient data management and predictive diagnostics.</p>
          </div>
          <div className={styles.useCaseItem}>
            <div className={styles.useCaseIcon}>
              <i className="fas fa-university"></i>
            </div>
            <h4>Finance</h4>
            <p>Enhance fraud detection and automate financial reporting.</p>
          </div>
          <div className={styles.useCaseItem}>
            <div className={styles.useCaseIcon}>
              <i className="fas fa-industry"></i>
            </div>
            <h4>Manufacturing</h4>
            <p>Optimize supply chain and predictive maintenance.</p>
          </div>
          {/* Add more use-case items as needed */}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className={styles.productTestimonials}>
        <h2>What Our Clients Say</h2>
        <div className={styles.testimonialsSlider}>
          {/* Testimonial Slide */}
          <div className={styles.testimonialSlide}>
            <p>
              "OpenHadron has revolutionized our AI operations. The seamless integration and automation capabilities are unparalleled."
            </p>
            <span>� Jane Smith, CTO of TechCorp Industries</span>
          </div>
          {/* Additional testimonial slides can be added here */}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className={styles.productCta}>
        <h2>Experience OpenHadron Today</h2>
        <p>
          Ready to transform your AI workflows? Get started with OpenHadron now.
        </p>
        <button className={styles.ctaButton}>Download Now</button>
      </section>
    </main>
  );
}

export default ProductsPage;