// src/pages/HomePage/HomePage.js
import React, { useEffect, useState } from 'react';
import ImageCarousel from './ImageCarousel';
import ProductFeatures from './ProductFeatures';
import CallToAction from './CallToAction';
import NewsHighlights from './NewsHighlights';
import styles from './HomePage.module.css';

function HomePage() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const viewportHeight = window.innerHeight;
      const scrollTrigger = viewportHeight * 0.75; // 75% of viewport height

      setIsScrolled(scrollTop > scrollTrigger); // Toggle based on scroll position
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Dark Landing Section */}
      <section className={`${styles.landingSection} ${isScrolled ? styles.scrolled : ''}`}>
        <div className={styles.landingSectionText}>OpenHadron</div>
      </section>

      {/* Main Content */}
      <main className={`${styles.mainContent} ${isScrolled ? styles.hiddenContent : ''}`}>
        <section className={`${styles.section} ${styles.imageCarouselSection}`}>
          <ImageCarousel />
        </section>
        <section className={`${styles.section} ${styles.productFeaturesSection}`}>
          <ProductFeatures />
        </section>
        <section className={`${styles.section} ${styles.callToActionSection}`}>
          <CallToAction />
        </section>
        <section className={`${styles.section} ${styles.newsHighlightsSection}`}>
          <NewsHighlights />
        </section>
      </main>
    </>
  );
}

export default HomePage;
