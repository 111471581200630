// src/components/ImageCarousel/ImageCarousel.js

import React from 'react';
import Slider from 'react-slick';
import OpenHadronImage from '../../../assets/images/OpenHadron.jpg';
import OpenHadronImage1 from '../../../assets/images/OpenHadron1.jpg';
import styles from './ImageCarousel.module.css'; // Component-specific styles

const ImageCarousel = () => {
  const settings = {
    dots: true, // Enable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <div className={styles.carouselContainer}>
      <Slider {...settings}>
        {/* Slide 1 */}
        <div className={styles.carouselItem}>
          <img
            src={OpenHadronImage}
            alt="Open Hadron"
            className={styles.carouselImage}
          />
          <div className={styles.carouselOverlay}>
            <h2>Welcome to SynchrotronAI</h2>
            <p>Your partner in advanced AI solutions.</p>
            <button className={styles.carouselButton}>Learn More</button>
          </div>
        </div>

        {/* Slide 2 */}
        <div className={styles.carouselItem}>
          <img
            src={OpenHadronImage1}
            alt="Open Hadron 1"
            className={styles.carouselImage}
          />
          <div className={styles.carouselOverlay}>
            <h2>Innovate with OpenHadron</h2>
            <p>Experience the next generation of AI technology.</p>
            <button className={styles.carouselButton}>Discover More</button>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ImageCarousel;
