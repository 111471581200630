// src/pages/DashboardPage/Dashboard/ChatBox/ChatBox.js

import React, { useState, useEffect, useRef } from 'react';
import { FaPaperPlane, FaPaperclip } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import styles from './ChatBox.module.css';

const ChatBox = ({ onMessageReceived, user, isLocked }) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState(null);
  const [reconnecting, setReconnecting] = useState(false);
  const ws = useRef(null);
  const textareaRef = useRef(null);
  const retryAttemptsRef = useRef(0);

  const maxRetries = 5;
  const pingInterval = 30000; // 30 seconds

  const onMessageReceivedRef = useRef(onMessageReceived);
  useEffect(() => {
    onMessageReceivedRef.current = onMessageReceived;
  }, [onMessageReceived]);

  useEffect(() => {
    const connectWebSocket = () => {
      if (!user || !user.id) {
        console.error('User not authenticated or missing user ID.');
        return;
      }

      const websocketUrl = `wss://${window.location.host}/api/ws`;
      ws.current = new WebSocket(websocketUrl);

      ws.current.onopen = () => {
        setError(null);
        setReconnecting(false);
        retryAttemptsRef.current = 0;

        const pingMessage = JSON.stringify({
          message_id: uuidv4(),
          timestamp: new Date().toISOString(),
          type: 'MESSAGE_TYPE_PING',
          content: {},
          metadata: { source: 'heartbeat' }
        });

        ws.current.pingInterval = setInterval(() => {
          if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(pingMessage);
            console.log('Sent MESSAGE_TYPE_PING to backend');
          }
        }, pingInterval);
      };

      ws.current.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          console.log('Received message from backend:', message); // Enhanced logging

          if (message.type === 'MESSAGE_TYPE_PONG') {
            console.log('Received MESSAGE_TYPE_PONG from backend');
            return; // Do not process PONG messages
          }

          if (message.type === 'MESSAGE_TYPE_EVENT_NOTIFICATION') {
            const taskStatus = (message.content.task_status || '').toUpperCase();
            const isCompleted = taskStatus === 'TASK_STATUS_COMPLETED';

            if (!isCompleted) {
              // In-Progress Task: Send as 'event' with parentId from metadata
              onMessageReceivedRef.current({
                id: uuidv4(),
                parentId: message.metadata.parent_id || null, // Correctly use parent_id from metadata
                sender: 'system',
                text: message.content.message_text || 'Event',
                timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                type: 'event',
              });
            } else {
              // Completed Task: Send as 'MESSAGE_TYPE_EVENT_NOTIFICATION' with parentId null
              onMessageReceivedRef.current({
                id: uuidv4(),
                parentId: null, // Standalone message
                sender: 'system',
                text: message.content.message_text || 'Task processing completed.',
                timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                type: 'MESSAGE_TYPE_EVENT_NOTIFICATION', // Consistent with schema
                task_status: 'TASK_STATUS_COMPLETED', // Include task_status for frontend categorization
              });
              console.log('Sent completion message to frontend');
            }
          } else if (message.type === 'MESSAGE_TYPE_ERROR') {
            // Handle error messages
            onMessageReceivedRef.current({
              id: uuidv4(),
              parentId: null,
              sender: 'error',
              text: message.content.message_text || 'An error occurred.',
              timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
              type: 'error',
            });
          } else {
            console.warn('Unknown message type:', message.type);
            // Optionally, ignore unknown message types
          }
        } catch (err) {
          console.error('Failed to parse WebSocket message:', err);
          setError(true);
        }
      };

      ws.current.onerror = (err) => {
        console.error('WebSocket error:', err);
        setError(true);
      };

      ws.current.onclose = () => {
        setError(true);
        clearInterval(ws.current.pingInterval);

        if (retryAttemptsRef.current < maxRetries) {
          retryAttemptsRef.current += 1;
          const timeout = Math.pow(2, retryAttemptsRef.current) * 1000;
          setReconnecting(true);
          console.log(`Reconnecting in ${timeout / 1000} seconds...`);
          setTimeout(() => connectWebSocket(), timeout);
        } else {
          console.error('Max retries reached. Could not reconnect.');
        }
      };
    };

    connectWebSocket();

    return () => {
      if (ws.current) {
        clearInterval(ws.current.pingInterval);
        ws.current.close();
      }
    };
  }, [user]);

  const messageSentRef = useRef(false); // Use useRef for persistent flag

  const handleSend = () => {
    if (input.trim() !== '' && !isLocked && !messageSentRef.current) { // Check the flag
      messageSentRef.current = true; // Set flag to true
      const messageId = uuidv4();
      const userMessage = {
        id: messageId,
        parentId: null, // Root message
        sender: 'user',
        text: input,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        type: 'main',
      };
  
      console.log('Sending user message:', userMessage);
      onMessageReceivedRef.current(userMessage);
  
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        const userMessagePayload = {
          message_id: messageId,
          timestamp: new Date().toISOString(),
          type: 'MESSAGE_TYPE_USER_MESSAGE',
          content: {
            message_text: input
          },
          metadata: {
            user_id: user?.id,
            parent_id: messageId
          }
        };
        ws.current.send(JSON.stringify(userMessagePayload));
        console.log('Sent MESSAGE_TYPE_USER_MESSAGE to backend');
      } else {
        console.error('WebSocket is not open. Message not sent.');
        setTimeout(() => {
          const botMessage = {
            id: uuidv4(),
            parentId: null, // No parent
            sender: 'bot',
            text: 'Something unexpected happened. Please try again later.',
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            type: 'main',
          };
          onMessageReceivedRef.current(botMessage);
        }, 2000);
      }
  
      setInput(''); // Clear input after sending
      messageSentRef.current = false; // Reset flag after sending
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isLocked) {
      e.preventDefault();
      handleSend(); // Ensure this fires once
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  return (
    <div className={styles.chatboxContainer}>
      <div className={styles.chatboxInputArea}>
        <button
          className={styles.chatboxAttachmentButton}
          onClick={() => alert('File attachment is not implemented yet.')}
          aria-label="Attach file"
        >
          <FaPaperclip />
        </button>
        <textarea
          className={styles.chatboxTextarea}
          placeholder="Type your message here..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          ref={textareaRef}
          rows={1}
        />
        <button
          className={styles.chatboxSubmitButton}
          onClick={handleSend}
          disabled={input.trim() === '' || isLocked} // Disable if input is empty or locked
          aria-label="Send message"
        >
          <FaPaperPlane />
        </button>
      </div>
      {isLocked && <div className={styles.chatboxLocked}>Waiting for task completion...</div>} {/* Optional UI feedback */}
      {error && <div className={styles.chatboxError}>Connection error. Please try again.</div>}
      {reconnecting && <div className={styles.chatboxReconnecting}>Reconnecting... Please wait.</div>}
    </div>
  );
};

export default ChatBox;
