import React, { useState } from 'react';
import { FaCheck, FaTimes, FaInfoCircle } from 'react-icons/fa';
import styles from './PricingPage.module.css';

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const plans = [
    {
      name: 'Free',
      monthlyPrice: '$0',
      yearlyPrice: '$0',
      features: [
        'Basic automation capabilities',
        'Single agent management',
        'Limited API access',
        'Community support'
      ],
      description: 'Perfect for individuals and small businesses to explore automation.',
      highlight: false
    },
    {
      name: 'Plus',
      monthlyPrice: '$40',
      yearlyPrice: '$384', // 20% discount for yearly
      features: [
        'Advanced automation tasks',
        'Multi-agent management (up to 5)',
        'Expanded API access',
        'Email support'
      ],
      description: 'Ideal for small businesses looking to enhance operations.',
      highlight: false
    },
    {
      name: 'Pro',
      monthlyPrice: '$120',
      yearlyPrice: '$1,152', // 20% discount for yearly
      features: [
        'Full access to advanced orchestration',
        'Unlimited agent management',
        'High API limits',
        'Priority support'
      ],
      description: 'Perfect for professionals and growing enterprises.',
      highlight: true
    },
    {
      name: 'Enterprise',
      monthlyPrice: '$400',
      yearlyPrice: '$3,840', // 20% discount for yearly
      features: [
        'Unrestricted access to all features',
        'Custom integrations',
        'Dedicated account manager',
        '24/7 premium support'
      ],
      description: 'Tailored for large organizations with complex needs.',
      highlight: false
    }
  ];

  return (
    <div className={styles.pricingPage}>
      <h1 className={styles.title}>Supercharge Your Workflow with Agent Orchestrator</h1>
      <p className={styles.subtitle}>Choose the plan that fits your ambition</p>
      
      <div className={styles.billingToggle}>
        <span className={billingCycle === 'monthly' ? styles.active : ''}>Monthly</span>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={billingCycle === 'yearly'}
            onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly')}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={billingCycle === 'yearly' ? styles.active : ''}>Yearly (Save 20%)</span>
      </div>

      <div className={styles.plansContainer}>
        {plans.map((plan, index) => (
          <div key={index} className={`${styles.planCard} ${plan.highlight ? styles.highlightPlan : ''}`}>
            {plan.highlight && <div className={styles.popularBadge}>Most Popular</div>}
            <h2 className={styles.planName}>{plan.name}</h2>
            <p className={styles.planPrice}>
              {billingCycle === 'monthly' ? plan.monthlyPrice : plan.yearlyPrice}
              <span className={styles.billingCycle}>/{billingCycle === 'monthly' ? 'month' : 'year'}</span>
            </p>
            <p className={styles.planDescription}>{plan.description}</p>
            <ul className={styles.featureList}>
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>
                  <FaCheck className={styles.checkIcon} /> {feature}
                </li>
              ))}
            </ul>
            <button className={styles.choosePlanButton}>
              {plan.name === 'Free' ? 'Get Started' : 'Choose Plan'}
            </button>
          </div>
        ))}
      </div>

      <div className={styles.comparisonSection}>
        <h2>Full Feature Comparison</h2>
        <table className={styles.comparisonTable}>
          <thead>
            <tr>
              <th>Feature</th>
              {plans.map(plan => <th key={plan.name}>{plan.name}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Automation Capabilities</td>
              <td>Basic</td>
              <td>Advanced</td>
              <td>Full Access</td>
              <td>Unrestricted</td>
            </tr>
            <tr>
              <td>Agent Management</td>
              <td>Single</td>
              <td>Up to 5</td>
              <td>Unlimited</td>
              <td>Unlimited + Custom</td>
            </tr>
            <tr>
              <td>API Access</td>
              <td>Limited</td>
              <td>Expanded</td>
              <td>High Limits</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Support</td>
              <td>Community</td>
              <td>Email</td>
              <td>Priority</td>
              <td>24/7 Premium</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.faq}>
        <h2>Frequently Asked Questions</h2>
        <div className={styles.faqItem}>
          <h3>What is Agent Orchestrator?</h3>
          <p>Agent Orchestrator is a powerful automation platform that allows you to streamline your workflows by managing and coordinating multiple AI agents efficiently.</p>
        </div>
        <div className={styles.faqItem}>
          <h3>Can I upgrade or downgrade my plan?</h3>
          <p>Yes, you can change your plan at any time. Upgrades take effect immediately, while downgrades will be applied at the start of your next billing cycle.</p>
        </div>
        {/* Add more FAQ items as needed */}
      </div>
    </div>
  );
};

export default PricingPage;