// src/pages/company/CareersPage/CareersPage.js
import React from 'react';
import styles from './CareersPage.module.css';

function CareersPage() {
  return (
    <main className={styles.careersPage}>
      {/* Hero Section */}
      <section className={styles.careersHero}>
        <h1>Join the Future of AI at SynchrotronAI</h1>
        <p>Be a part of a team that's revolutionizing the AI industry.</p>
      </section>

      {/* Introduction Section */}
      <section className={styles.careersIntro}>
        <h2>Why Work With Us?</h2>
        <p>
          SynchrotronAI is at the forefront of AI orchestration technology.
          Our flagship product, <strong>OpenHadron</strong>, is changing the way businesses integrate and automate AI tasks.
          We're looking for passionate individuals to join our innovative team.
        </p>
      </section>

      {/* Open Positions Section */}
      <section className={styles.openPositions}>
        <h2>Current Openings</h2>
        <div className={styles.positionsGrid}>
          {/* Position Card */}
          <div className={styles.positionCard}>
            <div className={styles.cardIcon}>
              <i className="fas fa-bullhorn"></i>
            </div>
            <div className={styles.cardContent}>
              <h3>Marketing Specialist</h3>
              <p>
                We're seeking a dynamic Marketing Specialist to drive our marketing strategies and enhance our brand presence.
              </p>
              <ul>
                <li><strong>Location:</strong> Remote</li>
                <li><strong>Type:</strong> Full-Time</li>
                <li><strong>Experience:</strong> Mid-Level</li>
              </ul>
              <button className={styles.viewDetailsButton}>View Details</button>
            </div>
          </div>

          {/* Additional Position Cards */}
          <div className={styles.positionCard}>
            <div className={styles.cardIcon}>
              <i className="fas fa-code"></i>
            </div>
            <div className={styles.cardContent}>
              <h3>Software Engineer</h3>
              <p>
                Join our development team to build cutting-edge AI orchestration software.
              </p>
              <ul>
                <li><strong>Location:</strong> Remote</li>
                <li><strong>Type:</strong> Full-Time</li>
                <li><strong>Experience:</strong> Senior-Level</li>
              </ul>
              <button className={styles.viewDetailsButton}>View Details</button>
            </div>
          </div>

          <div className={styles.positionCard}>
            <div className={styles.cardIcon}>
              <i className="fas fa-flask"></i>
            </div>
            <div className={styles.cardContent}>
              <h3>AI Research Scientist</h3>
              <p>
                Conduct groundbreaking research in AI and machine learning technologies.
              </p>
              <ul>
                <li><strong>Location:</strong> Remote</li>
                <li><strong>Type:</strong> Full-Time</li>
                <li><strong>Experience:</strong> PhD Required</li>
              </ul>
              <button className={styles.viewDetailsButton}>View Details</button>
            </div>
          </div>

          {/* Add more position cards as needed */}
        </div>
      </section>

      {/* Culture Section */}
      <section className={styles.careersCulture}>
        <h2>Our Culture</h2>
        <div className={styles.cultureContent}>
          <div className={styles.cultureIcon}>
            <i className="fas fa-users"></i>
          </div>
          <div className={styles.cultureText}>
            <p>
              At SynchrotronAI, we foster a collaborative and inclusive environment where innovation thrives.
              Our team is composed of diverse individuals who are passionate about technology and making a difference.
            </p>
            <p>
              We believe in continuous learning, open communication, and empowering our employees to reach their full potential.
            </p>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className={styles.careersBenefits}>
        <h2>Benefits & Perks</h2>
        <div className={styles.benefitsGrid}>
          <div className={styles.benefitItem}>
            <i className="fas fa-heartbeat"></i>
            <h4>Health & Wellness</h4>
            <p>Comprehensive health, dental, and vision insurance plans.</p>
          </div>
          <div className={styles.benefitItem}>
            <i className="fas fa-money-bill-wave"></i>
            <h4>Competitive Salary</h4>
            <p>We offer competitive salaries with opportunities for growth.</p>
          </div>
          <div className={styles.benefitItem}>
            <i className="fas fa-umbrella-beach"></i>
            <h4>Paid Time Off</h4>
            <p>Generous PTO and holiday schedules to maintain work-life balance.</p>
          </div>
          <div className={styles.benefitItem}>
            <i className="fas fa-laptop-code"></i>
            <h4>Remote Work</h4>
            <p>Flexible working arrangements, including remote options.</p>
          </div>
          <div className={styles.benefitItem}>
            <i className="fas fa-graduation-cap"></i>
            <h4>Professional Development</h4>
            <p>Access to training, workshops, and conferences.</p>
          </div>
          <div className={styles.benefitItem}>
            <i className="fas fa-coffee"></i>
            <h4>Office Perks</h4>
            <p>Fully stocked kitchen, game rooms, and relaxation spaces.</p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className={styles.careersCta}>
        <h2>Ready to Join Us?</h2>
        <p>
          If you're excited about shaping the future of AI, we'd love to hear from you.
        </p>
        <button className={styles.applyNowButton}>Apply Now</button>
      </section>
    </main>
  );
}

export default CareersPage;