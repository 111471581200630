// src/pages/terms/BrandGuidelinesPage/BrandGuidelinesPage.js
import React from 'react';
import styles from './BrandGuidelinesPage.module.css';

function BrandGuidelinesPage() {
  return (
    <div className={styles.brandGuidelinesPage}>
      <h1>Brand Guidelines</h1>
      <section className={styles.guidelineSection}>
        <h2>1. Overview</h2>
        <p>
          The SynchrotronAI brand represents innovation, reliability, and cutting-edge technology. Our brand guidelines ensure that our identity is communicated consistently across all channels.
        </p>
      </section>
      <section className={styles.guidelineSection}>
        <h2>2. Logo Usage</h2>
        <p>
          Use the SynchrotronAI logo as provided without modifications. Maintain clear space around the logo and use approved color variations for different backgrounds. Do not alter, rotate, or distort the logo in any way.
        </p>
      </section>
      <section className={styles.guidelineSection}>
        <h2>3. Color Palette</h2>
        <p>
          Our primary colors are #121212 (Dark Gray) and #00aaff (Blue). Use these colors consistently across all digital and print media to ensure a cohesive brand identity.
        </p>
      </section>
      <section className={styles.guidelineSection}>
        <h2>4. Typography</h2>
        <p>
          We use 'Roboto' for all text elements. This typeface should be used in various weights to create hierarchy and contrast. Avoid using more than two type sizes in a single layout.
        </p>
      </section>
      <section className={styles.guidelineSection}>
        <h2>5. Imagery</h2>
        <p>
          Use high-quality, relevant images that reflect our commitment to technology and innovation. Avoid using generic stock images that do not align with our brand values.
        </p>
      </section>
    </div>
  );
}

export default BrandGuidelinesPage;
