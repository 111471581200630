import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaGoogle, FaMicrosoft, FaApple } from 'react-icons/fa';
import styles from './ClientSignupLoginPage.module.css';

const ClientSignupLoginPage = () => {
  const [email, setEmail] = useState('');

  const handleGoogleLogin = () => {
    window.location.href = 'https://synchrotronai.com/api/auth/google';
  };

  const handleContinue = (e) => {
    e.preventDefault();
    // Handle email login/signup logic here
    console.log('Continue with email:', email);
  };

  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1 className={styles.heading}>Welcome back</h1>
        
        <form onSubmit={handleContinue}>
          <input
            type="email"
            placeholder="Email address*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={styles.emailInput}
            required
          />
          <button type="submit" className={styles.continueButton}>
            Continue
          </button>
        </form>

        <p className={styles.signUpText}>
          Don't have an account? <Link to="/signup" className={styles.signUpLink}>Sign Up</Link>
        </p>

        <div className={styles.orDivider}>
          <span>OR</span>
        </div>

        <button className={`${styles.socialButton} ${styles.googleButton}`} onClick={handleGoogleLogin}>
          <FaGoogle /> Continue with Google
        </button>
        <button className={`${styles.socialButton} ${styles.microsoftButton}`}>
          <FaMicrosoft /> Continue with Microsoft Account
        </button>
        <button className={`${styles.socialButton} ${styles.appleButton}`}>
          <FaApple /> Continue with Apple
        </button>

        <div className={styles.termsLinks}>
          <Link to="/terms" className={styles.termsLink}>Terms of Use</Link>
          {' | '}
          <Link to="/privacy" className={styles.termsLink}>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default ClientSignupLoginPage;