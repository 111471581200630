// src/pages/terms/PrivacyPage/PrivacyPage.js
import React from 'react';
import styles from './PrivacyPage.module.css';

function PrivacyPage() {
  return (
    <div className={styles.privacyPage}>
      <h1>Privacy Policy</h1>
      <section className={styles.privacySection}>
        <h2>1. Introduction</h2>
        <p>
          SynchrotronAI is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our services.
        </p>
      </section>
      <section className={styles.privacySection}>
        <h2>2. Information We Collect</h2>
        <p>
          We collect information you provide to us directly, such as when you create an account or contact us for support. We also collect information automatically, such as your IP address and usage data, when you interact with our services.
        </p>
      </section>
      <section className={styles.privacySection}>
        <h2>3. How We Use Your Information</h2>
        <p>
          We use your information to provide and improve our services, communicate with you, and protect our platform. We may also use your information for research and analytical purposes.
        </p>
      </section>
      <section className={styles.privacySection}>
        <h2>4. Sharing Your Information</h2>
        <p>
          We do not share your personal information with third parties, except as necessary to provide our services, comply with legal obligations, or protect our rights.
        </p>
      </section>
      <section className={styles.privacySection}> 
        <h2>5. Data Security</h2>
        <p>
          We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security measures are completely foolproof, and we cannot guarantee the security of your information.
        </p>
      </section>
      <section className={styles.privacySection}>
        <h2>6. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes. By continuing to use our services, you agree to the updated policy.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPage;
