// src/App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Layout components
import MainLayout from './layouts/MainLayout';
import ProtectedRoute from './components/ProtectedRoute';

// Main pages
import HomePage from './pages/HomePage/HomePage';
import ClientSignupLoginPage from './pages/ClientSignupLoginPage/ClientSignupLoginPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import PricingPage from './pages/PricingPage/PricingPage';

// Dashboard pages
import { 
  DashboardPage, 
  SettingsPage, 
  ChatHistoryPage, 
  IntegrationsPage,
  DashboardLayout
} from './pages/DashboardPage';

// Company pages
import {
  AboutUsPage,
  CareersPage,
  CharterPage,
  NewsPage,
  ResidencyPage,
  SecurityPage
} from './pages/company';

// API pages
import {
  APIOverviewPage,
  APIDocumentationPage,
  APIPricingPage
} from './pages/api';

// Community pages
import {
  EventsPage,
  ForumsPage,
  AmbassadorsPage
} from './pages/community';

// Developers pages
import {
  SDKsPage,
  CodeSamplesPage,
  APIIntegrationsPage
} from './pages/developers';

// Products pages
import {
  ProductsPage,
  OpenHadronPage,
  OpenHadronIndividualsPage,
  OpenHadronTeamsPage,
  OpenHadronEnterprisePage
} from './pages/products';

// Research pages
import {
  ResearchPage,
  ResearchIndexPage,
  AutonomousSystemsPage
} from './pages/research';

// Terms & Policies pages
import {
  TermsPage,
  PrivacyPage,
  BrandGuidelinesPage,
  PoliciesPage
} from './pages/terms';

function App() {
  return (
    <Routes>
      {/* Public Routes with MainLayout (Header and Footer) */}
      <Route element={<MainLayout />}>
        {/* Main Routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/signup-login" element={<ClientSignupLoginPage />} />
        <Route path="/pricing" element={<PricingPage />} />

        {/* Company Routes */}
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/about-us/charter" element={<CharterPage />} />
        <Route path="/about-us/residency" element={<ResidencyPage />} />
        <Route path="/about-us/security" element={<SecurityPage />} />
        <Route path="/news" element={<NewsPage />} />

        {/* API Routes */}
        <Route path="/api/overview" element={<APIOverviewPage />} />
        <Route path="/api/pricing" element={<APIPricingPage />} />
        <Route path="/api/documentation" element={<APIDocumentationPage />} />

        {/* Community Routes */}
        <Route path="/community/events" element={<EventsPage />} />
        <Route path="/community/forums" element={<ForumsPage />} />
        <Route path="/community/ambassadors" element={<AmbassadorsPage />} />

        {/* Developers Routes */}
        <Route path="/developers/sdks" element={<SDKsPage />} />
        <Route path="/developers/code-samples" element={<CodeSamplesPage />} />
        <Route path="/developers/api-integrations" element={<APIIntegrationsPage />} />

        {/* Products Routes */}
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/products/openhadron" element={<OpenHadronPage />} />
        <Route path="/products/openhadron/individuals" element={<OpenHadronIndividualsPage />} />
        <Route path="/products/openhadron/teams" element={<OpenHadronTeamsPage />} />
        <Route path="/products/openhadron/enterprise" element={<OpenHadronEnterprisePage />} />

        {/* Research Routes */}
        <Route path="/research" element={<ResearchPage />} />
        <Route path="/research/index" element={<ResearchIndexPage />} />
        <Route path="/research/autonomous-systems" element={<AutonomousSystemsPage />} />

        {/* Terms & Policies Routes */}
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/brand-guidelines" element={<BrandGuidelinesPage />} />
        <Route path="/policies" element={<PoliciesPage />} />
      </Route>

      {/* Dashboard Routes with DashboardLayout (No Header, Optional Footer) */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route path="settings" element={<SettingsPage />} />
        <Route path="chat-history" element={<ChatHistoryPage />} />
        <Route path="integrations" element={<IntegrationsPage />} />
      </Route>

      {/* 404 Page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;