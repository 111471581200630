import React from 'react';
import { FaPlug, FaRobot, FaShieldAlt, FaChartLine } from 'react-icons/fa';
import styles from './ProductFeatures.module.css';

function ProductFeatures() {
  return (
    <section className={styles.productFeatures}>
      <h2>Introducing OpenHadron</h2>
      <p>
        OpenHadron is a next-generation AI orchestrator designed to integrate effortlessly with leading AI platforms and third-party solutions. It empowers organizations to streamline processes, automate complex workflows, and enhance operational efficiency with minimal disruption.
      </p>
      <div className={styles.featuresGrid}>
        <div className={styles.featureItem}>
          <FaPlug className={styles.featureIcon} />
          <h4>Seamless Integration</h4>
          <p>
            Connect effortlessly with industry-leading AI platforms and tools, enabling a unified and cohesive AI strategy.
          </p>
        </div>
        <div className={styles.featureItem}>
          <FaRobot className={styles.featureIcon} />
          <h4>Advanced Automation</h4>
          <p>
            Leverage intelligent agents to automate complex tasks, reducing manual effort and accelerating decision-making.
          </p>
        </div>
        <div className={styles.featureItem}>
          <FaShieldAlt className={styles.featureIcon} />
          <h4>Robust Security</h4>
          <p>
            Safeguard your data with enterprise-grade security features designed to protect against evolving threats.
          </p>
        </div>
        <div className={styles.featureItem}>
          <FaChartLine className={styles.featureIcon} />
          <h4>Scalable Solutions</h4>
          <p>
            Adapt and scale your operations seamlessly as your business expands, ensuring consistent performance and reliability.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProductFeatures;
