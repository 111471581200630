// src/pages/research/ResearchPage/ResearchPage.js
import React from 'react';
import styles from './ResearchPage.module.css';

function ResearchPage() {
  return (
    <main className={styles.mainContent}>
      {/* Hero Section */}
      <section className={styles.researchHero}>
        <h1>Pioneering AI Research</h1>
        <p>Advancing the boundaries of artificial intelligence.</p>
      </section>

      {/* Mission Section */}
      <section className={styles.researchMission}>
        <h2>Our Mission</h2>
        <p>
          At SynchrotronAI, we are dedicated to exploring new frontiers in AI.
          Our research focuses on creating innovative solutions that address real-world challenges.
        </p>
      </section>

      {/* Areas of Research Section */}
      <section className={styles.researchAreas}>
        <h2>Areas of Focus</h2>
        <div className={styles.areasGrid}>
          <div className={styles.areaItem}>
            <i className="fas fa-robot"></i>
            <h4>Autonomous Systems</h4>
            <p>Developing AI systems capable of self-management and decision-making.</p>
          </div>
          <div className={styles.areaItem}>
            <i className="fas fa-brain"></i>
            <h4>Machine Learning</h4>
            <p>Innovating new algorithms for faster and more accurate learning.</p>
          </div>
          <div className={styles.areaItem}>
            <i className="fas fa-language"></i>
            <h4>Natural Language Processing</h4>
            <p>Enhancing AI's understanding of human language.</p>
          </div>
          <div className={styles.areaItem}>
            <i className="fas fa-eye"></i>
            <h4>Computer Vision</h4>
            <p>Improving AI's ability to interpret and understand visual information.</p>
          </div>
          <div className={styles.areaItem}>
            <i className="fas fa-shield-alt"></i>
            <h4>AI Ethics & Safety</h4>
            <p>Ensuring responsible and ethical development of AI technologies.</p>
          </div>
          <div className={styles.areaItem}>
            <i className="fas fa-network-wired"></i>
            <h4>Distributed AI Systems</h4>
            <p>Exploring scalable AI architectures for distributed environments.</p>
          </div>
        </div>
      </section>

      {/* Publications Section */}
      <section className={styles.researchPublications}>
        <h2>Recent Publications</h2>
        <div className={styles.publicationsList}>
          <div className={styles.publicationItem}>
            <h4>Advancements in Autonomous AI Systems</h4>
            <p>
              An in-depth look at self-managing AI. <a href="/research/paper1">Read more &raquo;</a>
            </p>
          </div>
          <div className={styles.publicationItem}>
            <h4>Ethical Considerations in AI Development</h4>
            <p>
              A discussion on AI ethics and safety. <a href="/research/paper2">Read more &raquo;</a>
            </p>
          </div>
          <div className={styles.publicationItem}>
            <h4>Scalable Distributed AI Architectures</h4>
            <p>
              Exploring AI in distributed systems. <a href="/research/paper3">Read more &raquo;</a>
            </p>
          </div>
          {/* Add more publication items as needed */}
        </div>
      </section>

      {/* Collaborations Section */}
      <section className={styles.researchCollaborations}>
        <h2>Collaborations & Partnerships</h2>
        <p>
          We collaborate with leading institutions and organizations to advance the field of AI.
        </p>
        <div className={styles.collaborationsLogos}>
          <div className={styles.collaborationItem}>
            <i className="fas fa-university"></i>
            <h4>MIT</h4>
          </div>
          <div className={styles.collaborationItem}>
            <i className="fas fa-university"></i>
            <h4>Stanford</h4>
          </div>
          <div className={styles.collaborationItem}>
            <i className="fas fa-rocket"></i>
            <h4>OpenAI</h4>
          </div>
          <div className={styles.collaborationItem}>
            <i className="fab fa-google"></i>
            <h4>Google AI</h4>
          </div>
          <div className={styles.collaborationItem}>
            <i className="fab fa-ibm"></i>
            <h4>IBM Research</h4>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className={styles.researchCta}>
        <h2>Join Our Research Team</h2>
        <p>
          Are you passionate about AI research? Explore opportunities to work with us.
        </p>
        <button>View Open Positions</button>
      </section>
    </main>
  );
}

export default ResearchPage;