import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaSearch, FaEnvelope } from 'react-icons/fa';
import styles from './NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.content}>
        <h1 className={styles.title}>404</h1>
        <div className={styles.glitchWrapper}>
          <div className={styles.glitch} data-text="Page Not Found">Page Not Found</div>
        </div>
        <p className={styles.message}>
          Oops! The page you're looking for doesn't exist or has been moved.
        </p>
        <div className={styles.actions}>
          <Link to="/" className={styles.actionButton}>
            <FaHome /> Go Home
          </Link>
          <Link to="/search" className={styles.actionButton}>
            <FaSearch /> Search
          </Link>
          <Link to="/contact" className={styles.actionButton}>
            <FaEnvelope /> Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;