import React, { useState } from 'react';
import styles from './SettingsPage.module.css';

const SettingsPage = () => {
  const [notifications, setNotifications] = useState(true);
  const [theme, setTheme] = useState('dark');
  const [language, setLanguage] = useState('english');

  return (
    <div className={styles.settingsPage}>
      <h1 className={styles.pageTitle}>Settings</h1>
      
      <section className={styles.settingSection}>
        <h2>Notifications</h2>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={notifications}
            onChange={() => setNotifications(!notifications)}
          />
          <span className={styles.slider}></span>
        </label>
        <p>Receive notifications: {notifications ? 'On' : 'Off'}</p>
      </section>

      <section className={styles.settingSection}>
        <h2>Theme</h2>
        <select
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
          className={styles.select}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <option value="system">System</option>
        </select>
      </section>

      <section className={styles.settingSection}>
        <h2>Language</h2>
        <select
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          className={styles.select}
        >
          <option value="english">English</option>
          <option value="spanish">Espa�ol</option>
          <option value="french">Fran�ais</option>
        </select>
      </section>

      <button className={styles.saveButton}>Save Changes</button>
    </div>
  );
};

export default SettingsPage;