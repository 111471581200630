// src/components/ProtectedRoute/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { authenticated, loading } = useContext(AuthContext);

  if (loading) {
    // Optionally, render a loading indicator while authentication status is being determined
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    return <Navigate to="/signup-login" replace />;
  }

  return children;
};

export default ProtectedRoute;
