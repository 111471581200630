// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'normalize.css'; // CSS reset
import 'slick-carousel/slick/slick.css'; // Slick Carousel CSS
import 'slick-carousel/slick/slick-theme.css'; // Slick Carousel Theme CSS
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome
import './styles/global.css'; // Your global styles
import './styles/utilities.css'; // Utility classes
import './styles/hero.css'; // Hero section styles
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
