import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NewsHighlights.module.css';

function NewsHighlights() {
  const newsArticles = [
    {
      id: 1,
      title: '\u{1F680} OpenHadron Launches: The Future of AI Orchestration',
      excerpt: 'We are excited to announce the launch of OpenHadron, our AI orchestrator that integrates seamlessly with leading AI platforms...',
      link: '/news/openhadron-launch',
      date: 'October 1, 2024',
    },
  ];

  return (
    <section className={styles.newsHighlights}>
      <h2>Latest News</h2>
      <div className={styles.newsGrid}>
        {newsArticles.map((article) => (
          <div key={article.id} className={styles.newsItem}>
            <h4>{article.title}</h4>
            <p className={styles.newsDate}>{article.date}</p>
            <p>{article.excerpt}</p>
            <Link to={article.link} className={styles.readMoreLink}>
              Read More &raquo;
            </Link>
          </div>
        ))}
      </div>
      <Link to="/news" className={styles.primaryButton}>
        View All News
      </Link>
    </section>
  );
}

export default NewsHighlights;