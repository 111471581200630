import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import axiosInstance from '../../utils/axiosInstance';
import logo from '../../assets/images/OpenHadronLogo.svg';
import styles from './Header.module.css';

function Header() {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();
  const { user, setUser, loading } = useContext(AuthContext);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsHeaderHidden(true);
      } else {
        setIsHeaderHidden(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleLoginSignupClick = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/signup-login');
    }
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.get('/auth/logout');
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <header className={`${styles.header} ${isHeaderHidden ? styles.headerHidden : ''}`}>
      <div className={styles.logo}>
        <Link to="/">
          <img src={logo} alt="SynchrotronAI Logo" className={styles.logoImage} />
        </Link>
      </div>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li><Link to="/research" className={styles.navLink}>Research</Link></li>
          <li><Link to="/products" className={styles.navLink}>Products</Link></li>
          <li><Link to="/careers" className={styles.navLink}>Careers</Link></li>
        </ul>
      </nav>
      {loading ? (
        <button className={styles.loginSignupButton} disabled>
          Loading...
        </button>
      ) : user ? (
        <button className={styles.loginSignupButton} onClick={() => navigate('/dashboard')}>
          Dashboard
        </button>
      ) : (
        <button className={styles.loginSignupButton} onClick={handleLoginSignupClick}>
          Login/Signup
        </button>
      )}
    </header>
  );
}

export default Header;
