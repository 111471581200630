import React, { useContext, useState, useRef, useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import {
  FaHistory,
  FaCogs,
  FaSlidersH,
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa';
import { AuthContext } from '../../../../contexts/AuthContext'; // Ensure this path is correct
import styles from './Sidebar.module.css';
import Logo from '../../../../assets/images/OpenHadronLogo.svg';

const Sidebar = () => {
  const { user, setUser } = useContext(AuthContext); // Destructure setUser from context
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  const userMenuRef = useRef(null);

  const handleLogout = () => {
    setUser(null); // Use setUser from context to clear the user
    navigate('/');
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen((prev) => !prev);
  };

  // Close the user menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target)
      ) {
        setIsUserMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.sidebar}>
      <Link to="/dashboard" className={styles.sidebarLogo}>
        <img src={Logo} alt="Logo" className={styles.logoImage} />
        <span className={styles.logoText}>Dashboard</span>
      </Link>
      <nav className={styles.sidebarNav}>
        <NavLink
          to="/dashboard/chat-history"
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebarItem} ${styles.active}`
              : styles.sidebarItem
          }
        >
          <FaHistory className={styles.sidebarIcon} />
          <span className={styles.sidebarText}>Chat History</span>
        </NavLink>
        <NavLink
          to="/dashboard/integrations"
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebarItem} ${styles.active}`
              : styles.sidebarItem
          }
        >
          <FaCogs className={styles.sidebarIcon} />
          <span className={styles.sidebarText}>Integrations</span>
        </NavLink>
        <NavLink
          to="/dashboard/settings"
          className={({ isActive }) =>
            isActive
              ? `${styles.sidebarItem} ${styles.active}`
              : styles.sidebarItem
          }
        >
          <FaSlidersH className={styles.sidebarIcon} />
          <span className={styles.sidebarText}>Settings</span>
        </NavLink>
      </nav>
      {user && (
        <div className={styles.userSection} ref={userMenuRef}>
          <div className={styles.userInfo} onClick={toggleUserMenu}>
            <span className={styles.userInitials}>
              {user.email.charAt(0).toUpperCase()}
            </span>
            <span className={styles.userEmail}>{user.email}</span>
            {isUserMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <div
            className={`${styles.userMenu} ${
              isUserMenuOpen ? styles.userMenuOpen : ''
            }`}
          >
            <p className={styles.userPlan}>Professional Plan</p>
            <button
              onClick={() => navigate('/dashboard/settings')}
              className={styles.userMenuItem}
            >
              Settings
            </button>
            <button
              onClick={() => navigate('/help')}
              className={styles.userMenuItem}
            >
              Help & Support
            </button>
            <button
              onClick={handleLogout}
              className={styles.userMenuItem}
            >
              Log Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
