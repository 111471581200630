// src/pages/terms/PoliciesPage/PoliciesPage.js
import React from 'react';
import styles from './PoliciesPage.module.css';

function PoliciesPage() {
  return (
    <div className={styles.policiesPage}>
      <h1>Policies</h1>
      <section className={styles.policySection}>
        <h2>Code of Conduct</h2>
        <p>
          At SynchrotronAI, we are committed to maintaining a respectful, inclusive, and supportive environment for all our employees, partners, and customers. We do not tolerate harassment, discrimination, or any form of unethical behavior. This policy applies to all interactions, whether in person or online.
        </p>
      </section>
      <section className={styles.policySection}>
        <h2>Acceptable Use Policy</h2>
        <p>
          Our Acceptable Use Policy outlines the types of activities that are not allowed when using our services. This includes, but is not limited to, illegal activities, abuse of the service, and attempts to circumvent security measures.
        </p>
      </section>
      <section className={styles.policySection}>
        <h2>Data Retention Policy</h2>
        <p>
          We retain user data for as long as necessary to provide our services and comply with legal obligations. Users may request deletion of their data in accordance with our privacy policy.
        </p>
      </section>
      <section className={styles.policySection}>
        <h2>DMCA Policy</h2>
        <p>
          SynchrotronAI respects the intellectual property rights of others and expects users of our services to do the same. If you believe that your copyrighted work has been used in a way that constitutes copyright infringement, please notify us following our DMCA guidelines.
        </p>
      </section>
    </div>
  );
}

export default PoliciesPage;
