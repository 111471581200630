// src/pages/DashboardPage/Dashboard/DashboardLayout.js

import React, { useState, useRef, useEffect, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import ChatBox from '../ChatBox/ChatBox';
import styles from './DashboardLayout.module.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import { AuthContext } from '../../../../contexts/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { FaSpinner, FaChevronDown, FaCheck } from 'react-icons/fa';

const DashboardLayout = () => {
  const [messages, setMessages] = useState([]);
  const [isLocked, setIsLocked] = useState(false); // State to manage message box lock
  const location = useLocation();
  const { user } = useContext(AuthContext);

  const sendMessage = (message) => {
    const newMessage = {
      id: message.id || uuidv4(),
      parentId: message.parentId !== undefined ? message.parentId : null,
      sender: message.sender,
      text: message.text,
      timestamp: message.timestamp || new Date().toISOString(), // Use ISOString for accurate timestamps
      type: message.type || 'main',
      task_status: message.task_status || null,
    };

    // Deduplication: Prevent adding duplicate messages based on 'id'
    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];
      // Sort the messages by timestamp to ensure correct order
      return updatedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    });

    console.log('New Message:', newMessage); // Debugging line

    // Manage locking mechanism
    if (newMessage.sender === 'user') {
      setIsLocked(true); // Lock the message box when a user sends a message
    }

    if (newMessage.type === 'MESSAGE_TYPE_EVENT_NOTIFICATION' && newMessage.task_status === 'TASK_STATUS_COMPLETED') {
      setIsLocked(false); // Unlock the message box when a completed status is received
    }
  };

  const isMainDashboard = location.pathname === '/dashboard' || location.pathname === '/dashboard/';

  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Ensure the new message is scrolled into view
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Organize messages into completed and in-progress
  const { completedMessages, inProgressMessages } = messages.reduce(
    (acc, msg) => {
      if (msg.type === 'MESSAGE_TYPE_EVENT_NOTIFICATION' && msg.task_status === 'TASK_STATUS_COMPLETED') {
        acc.completedMessages.push(msg);
      } else if (msg.parentId === null) {
        acc.inProgressMessages.push({ ...msg, processingSteps: [] });
      } else {
        const parent = acc.inProgressMessages.find((m) => m.id === msg.parentId);
        if (parent) {
          parent.processingSteps.push(msg);
        } else {
          console.error('Parent message not found for parentId:', msg.parentId);
        }
      }
      return acc;
    },
    { completedMessages: [], inProgressMessages: [] }
  );

  return (
    <div className={styles.dashboardLayout}>
      <Sidebar />
      <div className={styles.dashboardContentWrapper}>
        {isMainDashboard ? (
          <div className={styles.mainContent}>
            <div className={styles.messagesContainer}>
              {/* Render In-Progress Messages with Processing Steps */}
              {inProgressMessages.map((msg) => (
                <div key={msg.id} className={styles.messageWrapper}>
                  <div
                    className={`${styles.message} ${
                      msg.sender === 'user'
                        ? styles.messageUser
                        : msg.sender === 'bot'
                        ? styles.messageBot
                        : msg.sender === 'system'
                        ? styles.messageSystem
                        : msg.sender === 'error'
                        ? styles.messageError
                        : ''
                    }`}
                  >
                    <div className={styles.messageText}>
                      {msg.sender === 'user' ? (
                        msg.text
                      ) : msg.sender === 'bot' || msg.sender === 'system' ? (
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeSanitize]}
                        >
                          {msg.text}
                        </ReactMarkdown>
                      ) : msg.sender === 'error' ? (
                        <span className={styles.errorText}>{msg.text}</span>
                      ) : null}
                    </div>
                    <div className={styles.messageTimestamp}>{msg.timestamp}</div>
                  </div>

                  {/* Render processing steps as a dropdown if there are any */}
                  {msg.processingSteps.length > 0 && (
                    <details className={styles.processingDropdown} open>
                      <summary className={styles.dropdownSummary}>
                        {msg.task_status === 'TASK_STATUS_COMPLETED' ? (
                          <FaCheck className={styles.checkmark} />  // Checkmark on task completion
                        ) : (
                          <FaSpinner className={styles.spinner} />  // Spinner while task is in progress
                        )}
                        Processing Steps
                        <FaChevronDown className={styles.dropdownIcon} />
                      </summary>
                      <div className={styles.processingSteps}>
                        {msg.processingSteps.map((step) => (
                          <div key={step.id} className={styles.processingStep}>
                            {step.type === 'event' ||
                            step.type === 'MESSAGE_TYPE_EVENT_NOTIFICATION' ||
                            step.type === 'error' ? (
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeSanitize]}
                              >
                                {step.text}
                              </ReactMarkdown>
                            ) : (
                              <span>{step.text}</span>
                            )}
                            <div className={styles.stepTimestamp}>{step.timestamp}</div>
                          </div>
                        ))}
                      </div>
                    </details>
                  )}
                </div>
              ))}

              {/* Render Completed Messages Outside the Dropdown */}
              {completedMessages.map((msg) => (
                <div key={msg.id} className={styles.messageWrapper}>
                  <div className={`${styles.message} ${styles.messageCompletion}`}>
                    <div className={styles.messageText}>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeSanitize]}
                      >
                        {msg.text}
                      </ReactMarkdown>
                    </div>
                    <div className={styles.messageTimestamp}>{msg.timestamp}</div>
                  </div>
                </div>
              ))}

              <div ref={messagesEndRef} />
            </div>
            <ChatBox onMessageReceived={sendMessage} user={user} isLocked={isLocked} />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;